<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li><span>结算订单号</span><i class="hidden1">{{detail.mchSettleOrderNo?detail.mchSettleOrderNo:''}}</i></li>
          <li><span>结算流水号</span><i class="hidden1">{{detail.plfSettleOrderNo?detail.plfSettleOrderNo:''}}</i></li>
          <li><span>三方结算订单号</span><i class="hidden1">{{detail.upsSettleOrderNo?detail.upsSettleOrderNo:''}}</i></li>
          <li><span>产品类型</span><i class="hidden1">{{productCode[detail.productCode]}}</i></li>
          <li><span>分账商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.allocMchName" placement="top">
              <b>{{detail.allocMchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>分账商户编号</span><i class="hidden1">{{detail.allocMchId?detail.allocMchId:''}}</i></li>
          <li><span>所属商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>所属商户编号</span><i class="hidden1">{{detail.mchId?detail.mchId:''}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime?detail.createTime:''}}</i></li>
          <li><span>交易时间</span><i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i></li>
          <li><span>结算账户卡号</span><i class="hidden1">{{detail.accountNo?detail.accountNo:''}}</i></li>
          <li><span>结算账户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.accountName" placement="top">
              <b>{{detail.accountName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>结算金额</span><i class="hidden1">{{detail.settleAmount/100 | formatMoney}}</i></li>
          <li><span>到账金额</span><i class="hidden1">{{detail.remitAmount/100 | formatMoney}}</i></li>
          <li><span>平台手续费</span><i class="hidden1">{{detail.settleFee/100 | formatMoney}}</i></li>
          <li><span>分账商户手续费</span><i class="hidden1">{{detail.allocMchSettleFee/100 | formatMoney}}</i></li>
          <li><span>交易状态</span><i class="hidden1">{{orderStatus[detail.orderStatus]}}</i></li>
          <li><span>异步通知地址</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.notifyUrl" placement="top">
              <b>{{detail.notifyUrl}}</b>
            </el-tooltip>
          </i></li>
          <li><span>业务响应码</span><i class="hidden1">{{detail.resultCode?detail.resultCode:''}}</i></li>
          <li><span>业务响应码描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">结算订单通知</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
						<td>
						  <p>状态码</p>
						</td>
						<td>
						  <p>返回信息</p>
						</td>
          </tr>
          <tr v-for="(v,i) in notices" :key="i">
            <td>
              <p>{{v.notifyTime}}</p>
            </td>
            <td>
              <p>{{notifyType[v.notifyType]}}</p>
            </td>
            <td>
              <p>{{v.reachStatus?'已到达':'未响应'}}</p>
            </td>
            <td>
              <p>{{notifyStatus[v.notifyStatus]}}</p>
            </td>
						<td>
						  <p>{{v.resultCode}}</p>
						</td>
						<td>
						  <p>{{v.resultMessage}}</p>
						</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
	import {
		getSettleordersDetail
	} from '@/api/trading/subaccount.js'
  export default {
    data() {
      return {
				detail:'',
				notices:[],
        crumbs: "交易详情",
				orderStatus:{
					PROCESSING:'处理中',
					FAIL:'交易失败',
					SUCCESS:'交易成功'
				},
				notifyType:{
					PLATFORM:'平台',
					THIRD:'第三方'
				},
				notifyStatus:{
					SUCCESS:'成功',
					FAIL:'失败'
				},
				productCode:{
					2:'分账方-手工结算(可结算余额结算)',
					3:'分账方-D0 手工结算(当日收款金额结算)'
				}
      }
    },
		created() {
			this.getDetail()
		},
    methods: {
			// 获取详情
			getDetail(){
				getSettleordersDetail(this.$route.query.id).then(res=>{
					if(res){
						this.detail = res.resultData.detail
						this.notices = res.resultData.notices
					}
				})
			},
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
